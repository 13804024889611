import React from 'react'
import { Link } from 'gatsby'
import loadable from '@loadable/component'

import gifPathideoThumbnailPath from '../../images/gif/new-itproactive-short-video-6-comp-1.mp4'; // Adjust path as needed

const LiteYoutubeEmbedWithVideo = loadable(() => import('../common/LiteYoutubeEmbedWithVideo'))

const HomeIntroSectionUpdate = ({ title, description }) => (
  <div className="bg-white w-full border-b">
    <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
      <div className='w-full lg:w-1/2 p-4'>
        <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>{title ? title : "Section Title"}</h2>
        <p className='text-lg text-gray-800'>{description ? description : "Section description here.."}</p>
        <Link to="/assessment">
          <button className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
            Get Started
          </button>
        </Link>
      </div>

      <div className='w-full lg:w-1/2 p-4'>
        <LiteYoutubeEmbedWithVideo
          youtubeID="UBeSrNWe7BU"
          videoSrc={gifPathideoThumbnailPath}
        />
      </div>
    </div>
  </div>
)

export default HomeIntroSectionUpdate